/* Base App Styles */
.App {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  background-color: #f5f5f5;
  padding: 10px;
}

/* Logo */
.logo {
  width: 139px;
  height: 28px;
  cursor: pointer;
}

/* Chat Layout */
.chat-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  min-height: calc(var(--vh, 1vh) * 100);
  background-color: #f5f5f5;
  padding: 10px;
}

.chat-container {
  width: 100%;
  max-width: 550px;
  height: 90vh;
  height: calc(var(--vh, 1vh) * 90);
  background-color: white;
  border-radius: 12px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  position: relative;
}

/* Chat Header */
.chat-header {
  padding: 15px;
  border-bottom: 1px solid #eee;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: #E8F5E9;
}

.chat-title {
  margin: 0 15px 0 0;
  font-size: 1.5rem;
  color: #333;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

/* Chat Messages Area */
.chat-messages {
  flex: 1;
  overflow-y: auto;
  padding: 15px;
  display: flex;
  flex-direction: column;
  gap: 4px;
  scrollbar-width: thin;
  scrollbar-color: #888 #f1f1f1;

}

.chat-messages::-webkit-scrollbar {
  width: 8px;
}

.chat-messages::-webkit-scrollbar-track {
  background: #f1f1f1;
}

.chat-messages::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 4px;
}

.chat-messages::-webkit-scrollbar-thumb:hover {
  background: #555;
}

/* Message Styles */
.message {
  max-width: 70%;
  padding: 10px 15px;
  border-radius: 15px;
  word-wrap: break-word;
  opacity: 1;
  transition: opacity 0.3s ease-in-out;
}

.message.user {
  background-color: #2E7D32;
  color: white;
  align-self: flex-end;
  border-bottom-right-radius: 5px;
  margin: 16px 0;
}

.message.chatbot {
  background-color: #F6F6F6;
  color: #333;
  align-self: flex-start;
  border-bottom-left-radius: 5px;
  margin-bottom: 4px;
}

/* Input Area */
.chat-input-container {
  padding: 15px;
  border-top: 1px solid #eee;
}

.chat-input-wrapper {
  display: flex;
  gap: 8px;
  align-items: center;
}

.chat-input {
  flex: 1;
  padding: 12px;
  border: none;
  border-radius: 8px;
  resize: none;
  font-family: inherit;
  font-size: 1rem;
  line-height: 24px;
  min-height: 24px;
  max-height: 72px;
  background-color: #F6F6F6;
  transition: all 0.2s ease;
  overflow-y: auto;
  word-break: break-word;
  box-sizing: border-box;
  transform: translateZ(0);
}

.chat-input::-webkit-scrollbar {
  display: none;
}

.chat-input:hover:not(:disabled),
.chat-input:focus {
  background-color: white;
  border: 1px solid #6B6B6B;
  outline: none;
}

.chat-input:disabled {
  background-color: #f5f5f5;
  cursor: not-allowed;
}

.chat-input.show-scrollbar::-webkit-scrollbar {
  display: block;
  width: 4px;
}

.chat-input.show-scrollbar::-webkit-scrollbar-track {
  background: transparent;
}

.chat-input.show-scrollbar::-webkit-scrollbar-thumb {
  background: #ccc;
  border-radius: 2px;
}

/* Buttons */
.home-button {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 12px;
  background-color: #E8F5E9;
  color: #2E7D32;
  border: none;
  border-radius: 8px;
  cursor: pointer;
  transition: all 0.2s;
  height: 44px;
  min-width: 44px;
  font-weight: 600;
  font-size: 15px;
  flex-shrink: 0;
}

.home-button:active {
  background-color: rgba(46, 125, 50, 0.1);
  color: #2E7D32;
}

.send-button {
  padding: 0 20px;
  height: 44px;
  background-color: #2E7D32;
  color: white;
  border: none;
  border-radius: 8px;
  cursor: pointer;
  font-size: 1rem;
  transition: background-color 0.2s;
  white-space: nowrap;
}

.send-button:hover {
  background-color: #2E7D32;
}

.send-button:active {
  background-color: #2E7D32;
}

.chat-input-wrapper button:disabled {
  opacity: 0.6;
  cursor: not-allowed;
}

/* Products Section */
.chat-table-container {
  display: flex;
  flex-direction: column;
  gap: 12px;
  margin: 4px 0 8px 0;
}

.chat-table-item {
  background-color: #F6F6F6;
  max-width: 80%;
  border-radius: 8px;
  padding: 10px 15px;
  box-shadow: 0 1px 3px rgba(0,0,0,0.1);
}

.chat-table-item-kx {
  background-color: #E8F5E9;
  max-width: 80%;
  border-radius: 8px;
  padding: 15px;
  box-shadow: 0 1px 3px rgba(0,0,0,0.1);
}

.product-info {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.product-main {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  width: 100%;
  padding: 8px 0;
}

.product-main .row {
  display: flex;
  flex-direction: column;
  gap: 2px;
}

.info-row {
  display: flex;
  gap: 8px;
  align-items: center;
}

.label {
  width: 60px;
  font-size: 14px;
  color: #666;
  font-weight: 500;
  flex-shrink: 0;
}

.value {
  font-size: 14px;
  color: #333;
  flex: 1;
}

.products-name {
  color: #333333;
  font-weight: 600;
}

.products-price {
  font-size: 18px;
  font-weight: 600;
  color: #2E7D32;
}

.products-callBox {
  width: 84px;
  height: 36px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 4px;
  background-color: white;
  border: 1px solid #ddd;
  border-radius: 18px;
  cursor: pointer;
  transition: background-color 0.2s ease;
}

.products-callBox:hover {
  background-color: #f5f5f5;
}

.products-callBox img {
  width: 18px;
  height: 18px;
}

.products-callBox span {
  font-size: 14px;
  color: #333;
}

.show-more-button {
  width: 86%;
  padding: 12px 24px;
  margin: 4px 0;
  border: none;
  border-radius: 8px;
  background-color: #f5f5f5;
  color: #666;
  font-size: 14px;
  font-weight: 500;
  cursor: pointer;
  transition: all 0.2s ease-in-out;
  align-self: flex-start;
}

.show-more-button:hover {
  background-color: #eeeeee;
}

.show-more-button:active {
  background-color: #e0e0e0;
  transform: scale(0.98);
}

/* FAQ Section */
.faq-container {
  padding: 10px 15px;
}

.faq-list {
  display: flex;
  gap: 30px;
  overflow-x: auto;
  padding-bottom: 8px;
  -ms-overflow-style: none;
  scrollbar-width: none;
}

.faq-list::-webkit-scrollbar {
  display: none;
}

.faq-tag {
  margin-left: 7px;
  padding: 8px 16px;
  background-color: #f0f0f0;
  border: none;
  border-radius: 20px;
  white-space: nowrap;
  cursor: pointer;
  font-size: 0.9rem;
  color: #333;
  transition: background-color 0.2s;
}

.faq-tag:hover {
  background-color: #e0e0e0;
}

.faq-tag:active {
  background-color: #d0d0d0;
}

/* Media Queries */
@media (max-width: 768px) {
  .App, .chat-wrapper {
    padding: 0;
  }
  
  .chat-container {
    height: calc(var(--vh, 1vh) * 100);
    border-radius: 0;
  }

  .chat-header {
    padding: 10px;
  }

  .chat-title {
    font-size: 1.2rem;
  }

  .chat-input-container {
    position: sticky;
    bottom: 0;
    padding: 10px;
    padding-bottom: max(10px, env(safe-area-inset-bottom));
    background: white;
    z-index: 10;
  }

  .chat-input-wrapper {
    gap: 6px;
  }

  .chat-input {
    padding: 10px;
    font-size: 0.95rem;
  }

  .send-button {
    padding: 0 15px;
    height: 40px;
    font-size: 0.95rem;
  }

  .message {
    max-width: 85%;
    padding: 8px 12px;
  }

  .faq-container {
    padding: 8px 12px;
  }

  .faq-tag {
    padding: 6px 12px;
    font-size: 0.85rem;
  }

  .show-more-button {
    padding: 10px 0;
    font-size: 13px;
  }
}

@media (max-width: 480px) {
  .chat-title {
    font-size: 1.1rem;
  }

  .send-button {
    padding: 0 12px;
  }
}